const altByIcon = {
  'arrow': 'Arrow icon',
  'account': 'Account icon',
  'addtocart': 'Add to cart icon',
  'alert': 'Añert icon',
  'back-icon': 'Back icon',
  'bag': 'Bag icon',
  'bin': 'Bin icon',
  'breadcrumbs': 'Breadcrumbs icon',
  'check': 'Check icon',
  'clip': 'Clip icon',
  'close': 'Close icon',
  'close-white': 'Close white icon',
  'confirm-check': 'Confirm icon',
  'confirm': 'Confirm icon',
  'copy': 'Copy icon',
  'delete-search': 'Delete search icon',
  'download': 'Download icon',
  'dropdown-black': 'Black Dropdown icon',
  'dropdown-units': 'Units Dropdown icon',
  'dropdown': 'Dropdown icon',
  'edit': 'Edit icon',
  'excel': 'Excel icon',
  'facebook': 'Facebook icon',
  'instagram': 'Instagram icon',
  'linkedin': 'Linkedin icon',
  'location': 'Location icon',
  'menu' : 'Menu icon',
  'minus' : 'Minus icon',
  'modal-download' : 'Menu icon',
  'move' : 'Move icon',
  'narrow' : 'Narrow icon',
  'no-image' : 'No image icon',
  'open-cart' : 'Open cart icon',
  'open' : 'Open icon',
  'pedidos' : 'Pedidos icon',
  'productos-arrow' : 'Products arrow icon',
  'plus' : 'Plus icon',
  'productos' : 'Products icon',
  'search' : 'Search icon',
  'show-password' : 'Show password icon',
  'slider' : 'Slider icon',
  'truck' : 'Truck icon',
  'upload' : 'Upload icon',
  'youtube': 'Youtube icon',
  'zoom': 'Zoom icon',
}

export type TypeAppIcon = keyof typeof altByIcon
export const iconByName = (iconName: TypeAppIcon) => ({
  type: 'svg' as const,
  src: `/images/svg/i--${iconName}.svg`,
  alt: altByIcon[iconName],
  size: { width: 24, height: 24 }
})
