export const currencyBasedOnRegion = {
  'AD': { code: 'EUR'},
  'AL': { code: 'EUR'},  // "Albania",
  'AS': { code: 'USD'},  // "American Samoa"
  'AT': { code: 'EUR'},  // "Austria"
  'AU': { code: 'AUD'},  // "Australia",
  'BQ': { code: 'USD'},  // "Bonaire, Sint Eustatius and Saba" USD
  'CH': { code: 'EUR'},  // "Switzerland" fr
  'DE': { code: 'EUR'},
  'DEU': { code: 'EUR'},
  'EC': { code: 'USD'},  // "Ecuador" es USD
  'ES': { code: 'EUR'},
  'ESP': { code: 'EUR'},
  'FM': { code: 'USD'}, // "Micronesia", en USD
  'FR': { code: 'EUR'},
  'FRA': { code: 'EUR'},
  'GB': { code: 'GBP'},
  'GBR': { code: 'GBP'},
  'GR': { code: 'EUR'},
  'GU': { code: 'USD'}, // "Guam", 'en' USD
  'HT': { code: 'USD'}, // Haiti 'fr' USD
  'IE': { code: 'EUR'},
  'IM': { code: 'GBP'}, // Isle of Man 'en' GBP
  'IO': { code: 'USD'}, // "British Indian Ocean Territory" USD
  'IT': { code: 'EUR'},
  'ITA': { code: 'EUR'},
  'JE': { code: 'GBP'}, // Jersey 'en' GBP
  'MC': { code: 'EUR'}, // "Monaco" fr
  'MH': { code: 'USD'}, // "Marshall Islands", en USD
  'MP': { code: 'USD'}, // "Northern Mariana Islands", en USD
  'PA': { code: 'USD'}, // "Panama", es USD
  'PR': { code: 'USD'},
  'PT': { code: 'EUR'},
  'PW': { code: 'USD'}, // "Palau", en USD
  'SE': { code: 'EUR'}, // "Sweden" en
  'SV': { code: 'EUR'}, // "El Salvador" es USD
  'UM': { code: 'USD'}, // "USA Minor Outlying Islands", en USD
  'US': { code: 'USD'},
}

type TypeCurrencyFormatter = {
  price: number,
  localeRegion: string,
  decimals?: number,
  round?: boolean,
  trailingZeros?: boolean
}

export const currencyFormatter = ({ price, localeRegion = 'ES-es', decimals = 2, round = true, trailingZeros = false }:TypeCurrencyFormatter) => {
  const region = (localeRegion).split('-')[1]
  const digits = (!round && price % 1 !== 0) ? decimals : trailingZeros ? decimals : 0

  return new Intl.NumberFormat(localeRegion, {
    style: 'currency',
    currency: currencyBasedOnRegion?.[region]?.code ?? 'EUR',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(round ? Math.round(price) : price)
}

type TypeCalcPriceWithTax = {
  price: number
  tax: number
}

export const calcPriceWithTax = ({ price, tax }:TypeCalcPriceWithTax) => {
  if(!price || typeof price !== 'number') return null

  const vat = typeof tax === 'number' ? tax : 0
  const taxAsValue = price * vat / 100

  return Math.round(price + taxAsValue)
}

type priceObject = {
  [key:string]: number
}

export const getAllPricesCurrency = (priceObject:priceObject, locale, decimals, round) =>{
  const newObj = {}
  Object.entries(priceObject).forEach(([key, value])=> {
    newObj[key+'Currency'] = currencyFormatter({price: value, localeRegion:locale, decimals, round})
  })

  return newObj
}
