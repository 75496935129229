import { memo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { mq, vw, designGrid, getP15_1, getP15_3 } from '@/styles'
import { Button } from '@/components/Button'
import { MODALS } from '@/context'
import { useTranslator, useModal } from '@/hooks'

export const SectionRrss = memo(() => {
  const { toggle } = useModal(MODALS.LANG)
  const { t } = useTranslator()

  return (
    <Section>
      <div className='row-copyright'>
        <span>© Vi Enology {new Date().getFullYear()}</span>
        <Link href={t('legal_link') as string}>{t('legal')}</Link>
      </div>
      <ul className='list--rrss'>
        <li>
          <a href='https://www.facebook.com/vienology/' rel='noreferrer' target='_blank' title='facebook'>
            <img src='/images/svg/icon-fb.svg' alt='Facebook logo' width={30} height={30} />
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/vienology/' rel='noreferrer' target='_blank' title='instagram'>
            <img src='/images/svg/icon-ig.svg' alt='Instagram logo' width={30} height={30} />
          </a>
        </li>
        <li>
          <a href='https://www.linkedin.com/company/vienology/' rel='noreferrer' target='_blank' title='linkedin'>
            <img src='/images/svg/icon-in.svg' alt='Linkedin logo' width={30} height={30} />
          </a>
        </li>
        <li>
          <a href='https://www.youtube.com/channel/UCl0GoR7CLvbn9M-KGQCmNRw/featured' rel='noreferrer' target='_blank' title='youtube'>
            <img src='/images/svg/icon-yt.svg' alt='Youtube logo' width={30} height={30} />
          </a>
        </li>
      </ul>
      <Button onClick={toggle} className='btn--country-and-lang' outline={true} bgType='background'>{t('change')}</Button>
    </Section>
  )
})

const Section = styled.section`
  grid-column: 1 / span 6;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mq.greaterThan('tablet')} {
    ${designGrid({})}
    align-items: flex-end;
    grid-column: 1 / span 12;
    grid-row: 3 / span 1;
  }

  .list--rrss {
    align-self: flex-end;
    grid-column: 4 / span 3;
    grid-row: 1 / span 1;
    display: flex;
    list-style-type: none;

    ${mq.greaterThan('tablet')} {
      grid-column: 6 / span 3;
      grid-row: 2 / span 1;
      margin-bottom: 0;
    }

    li {
      align-items: center;
      display: flex;
      margin-right: ${vw(15, 'mobile')};

      ${mq.greaterThan('tablet')} {
        margin-right: ${vw(15, 'desktop')};
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      opacity: .4;
      text-decoration: none;
      transition: 300ms opacity ease-out;

      &:hover {
        opacity: 1;
      }

      img {
        height: ${vw(30, 'mobile')};
        width: ${vw(30, 'mobile')};

        ${mq.greaterThan('tablet')} {
          height: ${vw(30, 'desktop')};
          min-height: 30px;
          min-width: 30px;

          width: ${vw(30, 'desktop')};
        }
      }
    }
  }

  .row-copyright {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
    margin-top: ${vw(120, 'mobile')};

    ${mq.greaterThan('tablet')} {
      flex-direction: row;
      grid-column: 2 / span 4;
      margin-top: ${vw(45, 'desktop')};
    }

    > a,
    > span {
      ${getP15_1()}
      color: var(--color-background);
      margin-bottom: 0;
      opacity: .5;
    }

    > span {
      margin-bottom: ${vw(5, 'mobile')};
      margin-right: 0;
      font-weight: 700;

      ${mq.greaterThan('tablet')} {
        margin-bottom: 0;
        font-weight: 700;
        margin-right: ${vw(30, 'desktop')};
      }
    }

    > a {
      transition: 300ms opacity linear;
      font-weight: 400;
      text-decoration: underline;

      &:hover {
        opacity: 1;
      }

      ${mq.greaterThan('tablet')} {
        font-weight: 400;
      }
    }
  }

  .btn--country-and-lang {
    ${getP15_3()}
    border-radius: 90px;
    color: var(--color-background);
    grid-column: 4 / span 3;
    height: ${vw(50, 'mobile')};
    margin: auto auto 0 0;
    padding: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')} {
      grid-column: 9 / span 2;
      grid-row: 2 / span 1;
      height: ${vw(40, 'desktop')};
      padding: 0  ${vw(20, 'desktop')};
      letter-spacing: -0.5px;
    }
  }
`
