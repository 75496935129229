'use client'
import { memo } from 'react'
import styled from 'styled-components'
import { mq, vw, createUnderline, getP53, getP26_1, getP20_Mobile } from '@/styles'
import { useTranslator } from '@/hooks'

export const CallUs = memo(() => {
  const { t } = useTranslator()

  return (
    <P>
      {t('any_question')} <br /><a href={`mailto:${t('email')}`}>{t('write_us')}<span></span></a> {t('or_call')}<br />
      <a href={`tel:00${t('phone_link')}`} className='phone'>{t('phone')}<span></span></a>
    </P>
  )
})

const P = styled.p`
  color: var(--color-background);
  font-family: var(--font-platform);
  ${getP20_Mobile()}
  grid-column: 1 / span 4;
  letter-spacing: .5px;
  margin-bottom: ${vw(80, 'mobile')};
  font-weight: 600;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(80, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    ${getP26_1()}
    grid-column: 9 / span 4;
    letter-spacing: 1px!important;
    font-family: var(--font-platform);
    margin-bottom: ${vw(60, 'desktop')};
    font-weight: 600!important;
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 60px;
  }

  a {
    ${createUnderline({ reverse: true })}

    &.phone {
      ${createUnderline({ height: 3 })}
    }
  }

  .phone {
    ${getP53()}
    font-family: var(--font-platform);
    display: inline-block;
    margin-top: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 20px;
    }
  }
`
