import { memo, useMemo } from 'react'
import { useParams, useRouter } from 'next/navigation'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { cookie } from '@/utils'
import { mq, vw, font15_20 } from '@/styles'
import { Logo } from './Logo'

export const Navbar = memo(() => {
  const { push } = useRouter()
  const { lang, slug } = useParams()
  const color = useMemo(() => !slug ? '#EFDCC7' : '#76273C', [slug])

  const onCountryChange = (value) => {
    cookie.set('VIENOLOGY_REGION', value)
    push(`/${value}`)
  }

  return (
    <Nav>
      <Logo />
      <Div $color={color}>
        <a onClick={() => onCountryChange('es')} className={(!lang || lang === 'es') ? 'active' : ''}>ES</a>
        <a onClick={() => onCountryChange('fr')} className={lang === 'fr' ? 'active' : ''}>FR</a>
        <a onClick={() => onCountryChange('de')} className={lang === 'de' ? 'active' : ''}>DE</a>
        <a onClick={() => onCountryChange('en')} className={lang === 'en' ? 'active' : ''}>UK</a>
        <a onClick={() => onCountryChange('pt')} className={lang === 'pt' ? 'active' : ''}>PT</a>
      </Div>
    </Nav>
  )
})

const Nav = styled<any>(motion.nav)`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  left: 0;
  top: 0;
  position: absolute;
  transition: 300ms background-color ${({ ease }) => ease}, 300ms opacity ${({ ease }) => ease};
  width: 100%;
  z-index: 500;

  ${mq.greaterThan('tablet')} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: initial;
  }

  .logo {
    margin: ${vw(19, 'mobile')} 0 0;
    grid-area: 2 / 1 / span 1 / span 1;
    justify-self: center;

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 2 / span 1 / span 1;
      margin: ${vw(50, 'desktop')} 0 0;
    }
  }
`

const Div = styled.div<{ $color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap:${vw(15, 'mobile')};
  margin: ${vw(10, 'mobile')} ${vw(11, 'mobile')} 0 0;
  grid-area: 1 / 1 / span 1 / span 1;
  justify-self: end;
  align-self: start;

  ${mq.greaterThan('tablet')} {
    column-gap:${vw(15, 'desktop')};
    margin: ${vw(14, 'desktop')} ${vw(40, 'desktop')} 0 0;
    grid-area: 1 / 3 / span 1 / span 1;
  }

  a {
    ${font15_20(true)}
    color: ${({ $color }) => $color};

    ${mq.greaterThan('tablet')} {
      ${font15_20(false)}
    }
  }

  a.active {
    text-decoration: underline;
  }
`
