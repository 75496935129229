'use client'
import type { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { TranslationsProvider, ModalsProvider } from '@/context'
import { Susy } from '@/styles'
import { STAGING, PRO } from '@/utils'
import { Footer } from './footer'
import { Navbar } from './Navbar'
// import { LoadingScreen } from './LoadingScreen'

const DynamicModalLang = dynamic(() => import('@/components/modals/ModalLang').then(mod => mod.ModalLang), { ssr: false })
// const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })

type Props = {
  children: ReactNode
}

const ClientProviders: FC<Props> = ({ children }) => {
  return (
    <StyledComponentsRegistry>
      <TranslationsProvider>
        <ModalsProvider>
          <Navbar />
          {children}
          <Footer />
          {/* <LoadingScreen/> */}
          <DynamicModalLang />
        </ModalsProvider>
      </TranslationsProvider>
      {(!STAGING && !PRO) && <Susy />}
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
