import { memo } from 'react'
import styled from 'styled-components'
import { mq, vw, getP15_1, getP26_1 } from '@/styles'
import { useTranslator } from '@/hooks'

const Section = styled.section`
  grid-column: 4 / span 3;
  padding: 0;

  ${mq.greaterThan('tablet')} {
    align-items: flex-end;
    grid-column: 6 / span 2;
    grid-row: 1 / span 2;
  }

  .list--pages {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style-type: none;
    margin-bottom: ${vw(10, 'mobile')};

    h2, p {
      color: var(--color-background);
    }

    h2 {
      ${getP26_1()}
      text-align: left;
      font-family: var(--font-platform);
      margin-bottom: ${vw(10, 'mobile')};
      font-size: ${vw(20, 'mobile')};
      font-weight: 700!important;
      letter-spacing: .5px!important;

      ${mq.greaterThan('tablet')} {
        letter-spacing: 1px!important;
        margin-bottom: ${vw(25, 'desktop')};
      }
    }

    p {
      ${getP15_1()}
      font-size: ${vw(15, 'mobile')};
      line-height: ${vw(21, 'mobile')};
      font-weight: 400!important;

      ${mq.greaterThan('tablet')} {
        ${getP15_1()}
      }
    }


    ${mq.greaterThan('tablet')} {
      margin-bottom: 0;
    }
  }
`

export const SectionPages = memo(() => {
  const { t } = useTranslator()

  return (
    <Section>
      <ul className='list--pages'>
        <h2>{t('headline')}</h2>
        <p>{t('address_one')}<br/>{t('address_two')}<br/>{t('address_three')}<br/>{t('address_four')}</p>
      </ul>
    </Section>
  )
})
