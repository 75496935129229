import { memo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, createUnderline, getTransition } from '@/styles'
import { useParams, usePathname } from 'next/navigation'
import { useTranslator } from '@/hooks'

export const NavList = memo(() => {
  const { lang, slug } = useParams()
  const pathname = usePathname()
  const { t } = useTranslator()

  return (
    <Ul className='nav-list' initial='inactive' animate='active' exit='exit' variants={listVariants}>
      <motion.li variants={itemVariants}>
        <Link href={`/${lang ? lang + '' : ''}`} className={!slug ? 'active' : ''}>
          Home
        </Link>
      </motion.li>
      <motion.li variants={itemVariants}>
        <Link href={t('nav_about_slug') as string} className={pathname.includes(t('nav_about_slug') as string) ? 'active' : ''}>
          {t('nav_about')}
        </Link>
      </motion.li>
    </Ul>
  )
})

const Ul = styled(motion.ul)`
  display: none;

  ${mq.greaterThan('tablet')} {
    display: flex;
    grid-column: 4 / span 4;
  }

  button,
  a {
    display: inline-block;
    font-family: var(--font-platform);
    padding-bottom: 4px;

    ${mq.greaterThan('tablet')} {
      font-weight: bold;
      padding: 0 ${vw(15, 'desktop')};
      position: relative;
      ${createUnderline({ height: 2 })}
    }

    > span {
      right: ${vw(30, 'desktop')};
      max-width: calc(100% - ${vw(30, 'desktop')});
    }
  }

  a {
    color: var(--color-background);
    transition: color 300ms linear;

    &:hover {
      color: var(--color-white);
    }
  }
`

const transition = {
  ...getTransition()
}

const itemVariants = {
  inactive: {
    x: -10,
    opacity: 0,
    transition
  },
  active: {
    x: 0,
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0
  }
}

const listVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  active: {
    opacity: 1,
    transition: {
      ...getTransition(),
      staggerChildren: .01
    }
  },
  exit: {
    opacity: 0,
    transition
  }
}
