/* eslint-disable quotes */
import { CLIENT_NAME, HOSTNAME } from '@/utils'

export const jsonLdHomepage = (_data, locale) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": `https://${HOSTNAME}${locale === 'es' ? '' : `/${locale}`}`,
    "name": `${CLIENT_NAME}`,
    "description": "No es una simple claraboya",
    "publisher": {
      "@type": "Organization",
      "name": `${CLIENT_NAME}`,
      "logo": {
        "@type": "ImageObject",
        "url": `https://${HOSTNAME}/images/svg/logo.svg`
      }
    },
    "potentialAction": {
      "@type": "RegisterAction",
      "name": "Register",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `https://${HOSTNAME}/#modelos`,
      },
      "description": "Discover our AyB products"
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Modelos",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "product",
            "name": "Modelo 300",
            // "description": ""
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "product",
            "name": "Modelo 600",
            // "description": ""
          }
        }
        // Additional categories/services can be listed here
      ]
    }
  }

  return JSON.stringify(schema)
}
