import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"preload\":true,\"src\":[{\"path\":\"/fonts/BasierCircle/BasierCircle-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"/fonts/BasierCircle/BasierCircle-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"/fonts/BasierCircle/BasierCircle-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"/fonts/BasierCircle/BasierCircle-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"}],\"variable\":\"--font-basier-circle\"}],\"variableName\":\"basierCircle\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"preload\":true,\"src\":[{\"path\":\"/fonts/Manic/Manic-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"}],\"variable\":\"--font-manic\"}],\"variableName\":\"manic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"preload\":true,\"src\":[{\"path\":\"/fonts/Platform/Platform-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"/fonts/Platform/Platform-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"/fonts/Platform/Platform-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"}],\"variable\":\"--font-platform\"}],\"variableName\":\"platform\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ClientProviders.tsx");
