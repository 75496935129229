import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, vw } from '@/styles'
import { useParams } from 'next/navigation'

const _Logo = ({ color = '#EFDCC7' }) => (
  <svg width="174px" height="89px" viewBox="0 0 174 89" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="202201---Vi-enology-landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="About-us" transform="translate(-633.000000, -23.000000)" fill={color} fillRule="nonzero">
        <g id="logo+tagline-vinology" transform="translate(633.000000, 23.000000)">
          <rect id="Rectangle" x="80.609989" y="29.7946192" width="7.7718509" height="35.3105815"></rect>
          <polygon id="Path" points="153.783603 73.5551181 157.306096 63.6982136 147.840892 39.2598057 156.387532 39.2598057 161.259916 53.1218271 166.084374 39.2598057 174 39.2598057 161.259916 73.5551181"></polygon>
          <polygon id="Path" points="0.543150936 75.3218463 2.28442894 75.3218463 5.66314726 84.3313607 9.05784062 75.3218463 10.6952809 75.3218463 6.45391113 86.2579739 4.79250826 86.2579739"></polygon>
          <path d="M13.3311605,86.2579739 L11.8295079,86.2579739 L11.8295079,77.8080566 L13.3311605,77.8080566 L13.3311605,86.2579739 Z M11.581895,75.9853686 C11.5775305,75.7211482 11.6804792,75.4664647 11.8671794,75.2796071 C12.0538797,75.0927495 12.3083488,74.989714 12.5723467,74.9940822 C12.8371518,74.9919535 13.0919551,75.0951984 13.2806986,75.2811025 C13.4694421,75.4670066 13.5766634,75.7203403 13.5787734,75.9853686 C13.5766634,76.2525172 13.4686004,76.5078842 13.278359,76.6952875 C13.0881176,76.8826907 12.8312826,76.9867779 12.5643592,76.9846493 C12.3002852,76.9846579 12.0471419,76.8791224 11.8611648,76.6914876 C11.6751878,76.5038527 11.5717778,76.2496565 11.5739075,75.9853686" id="Shape"></path>
          <path d="M19.729159,80.8378755 C19.729159,79.7186812 19.2499082,79.0551588 18.2275064,79.0551588 C17.2051047,79.0551588 16.6779288,79.7826352 16.6779288,80.885841 L16.6779288,86.2579739 L15.1762762,86.2579739 L15.1762762,77.8080566 L16.6779288,77.8080566 L16.6779288,78.5675099 C17.136268,77.9871603 17.8491462,77.6677367 18.5869445,77.7121256 C20.5119354,77.7121256 21.2228241,79.0391703 21.2228241,80.6779906 L21.2228241,86.2739623 L19.729159,86.2739623 L19.729159,80.8378755 Z" id="Path"></path>
          <path d="M24.5376423,86.2579739 L23.0359897,86.2579739 L23.0359897,77.8080566 L24.5376423,77.8080566 L24.5376423,86.2579739 Z M22.7883768,75.9853686 C22.7840123,75.7211482 22.886961,75.4664647 23.0736613,75.2796071 C23.2603615,75.0927495 23.5148306,74.989714 23.7788285,74.9940822 C24.322718,74.9984723 24.7612927,75.4410031 24.7612927,75.9853686 C24.7613269,76.2531879 24.6539445,76.5098122 24.4632314,76.6976802 C24.2725183,76.8855481 24.014438,76.9889344 23.7468784,76.9846493 C23.4828045,76.9846579 23.2296612,76.8791224 23.0436841,76.6914876 C22.8577071,76.5038527 22.7542971,76.2496565 22.7564267,75.9853686" id="Shape"></path>
          <path d="M33.084282,85.0588371 C34.3233769,85.1342347 35.4828839,84.4448768 36.0094382,83.3197533 C36.5359926,82.1946297 36.3228637,80.8617992 35.4717655,79.9573434 C34.6206672,79.0528876 33.3041142,78.7601365 32.1504199,79.218803 C30.9967256,79.6774696 30.2397397,80.7945837 30.2407271,82.0370123 C30.1848774,82.8236159 30.4604341,83.5977246 31.000638,84.1717953 C31.5408419,84.745866 32.2964068,85.0675225 33.084282,85.0588371 M35.9517995,85.0108716 C35.2570447,85.9130985 34.17414,86.4297676 33.036357,86.4018703 C31.8682543,86.4558486 30.7320487,86.0116525 29.9097762,85.179543 C29.0875038,84.3474335 28.6562631,83.2054316 28.7230995,82.0370123 C28.6766404,80.878975 29.1098004,79.7530917 29.9202676,78.9252947 C30.7307348,78.0974977 31.8466397,77.6411934 33.0044069,77.6641602 C34.0929608,77.6237536 35.1433138,78.0688461 35.8719243,78.8792854 L35.8719243,74.7062894 L37.3735769,74.7062894 L37.3735769,86.2579739 L35.943812,86.2579739 L35.9517995,85.0108716 Z" id="Shape"></path>
          <path d="M40.7363202,86.2579739 L39.2186926,86.2579739 L39.2186926,77.8080566 L40.7363202,77.8080566 L40.7363202,86.2579739 Z M38.9710797,75.9853686 C38.9668112,75.7253248 39.0664956,75.4743458 39.2479807,75.2882043 C39.4294658,75.1020628 39.6777174,74.9961789 39.9375689,74.9940822 C40.2037491,74.9898109 40.4606115,75.0921064 40.6510899,75.278242 C40.8415682,75.4643776 40.9498876,75.7189381 40.9519831,75.9853686 C40.9498731,76.2525172 40.8418101,76.5078842 40.6515687,76.6952875 C40.4613273,76.8826907 40.2044923,76.9867779 39.9375689,76.9846493 C39.6734949,76.9846579 39.4203516,76.8791224 39.2343745,76.6914876 C39.0483975,76.5038527 38.9449875,76.2496565 38.9471172,75.9853686" id="Shape"></path>
          <path d="M49.2749725,85.0588371 C50.5130755,85.1276018 51.6678911,84.4337201 52.1893198,83.3077245 C52.7107485,82.1817288 52.4933438,80.8513247 51.6406671,79.9502536 C50.7879904,79.0491826 49.472475,78.7596646 48.3207701,79.2196123 C47.1690653,79.67956 46.4140757,80.7959601 46.4154425,82.0370123 C46.3593307,82.8264282 46.6370057,83.6031476 47.1807518,84.1777544 C47.7244978,84.7523612 48.484338,85.0720419 49.2749725,85.0588371 M52.0546272,85.1068025 C51.3611193,85.9595785 50.3090705,86.4387232 49.2110723,86.4018703 C48.0436151,86.4560603 46.9080608,86.0116469 46.0870053,85.1792274 C45.2659497,84.3468078 44.8365992,83.204657 44.9058024,82.0370123 C44.8566246,80.8740541 45.2922227,79.7428864 46.1085212,78.913774 C46.9248197,78.0846615 48.048495,77.6320791 49.2110723,77.6641602 C50.3278289,77.6244447 51.4005125,78.1022501 52.1185274,78.9592279 L52.1185274,77.8080566 L53.5562798,77.8080566 L53.5562798,89 L52.0546272,89 L52.0546272,85.1068025 Z" id="Shape"></path>
          <path d="M56.855123,83.2121664 C56.855123,84.3313607 57.3263863,84.9948831 58.316838,84.9948831 C59.3072898,84.9948831 59.8504407,84.2674068 59.8504407,83.1642009 L59.8504407,77.8080566 L61.3680683,77.8080566 L61.3680683,86.2579739 L59.9063533,86.2579739 L59.9063533,85.4585493 C59.4676896,86.0562836 58.7522408,86.3856069 58.0133125,86.3299221 C56.1362468,86.3299221 55.393408,85.0348543 55.393408,83.3560628 L55.393408,77.7600911 L56.8950606,77.7600911 L56.855123,83.2121664 Z" id="Path"></path>
          <path d="M65.8650386,85.154768 C66.747315,85.154768 67.4625413,84.4389389 67.4625413,83.5559189 C67.4625413,82.672899 66.747315,81.9570699 65.8650386,81.9570699 C65.4339311,81.9311337 65.0123684,82.0912369 64.7069791,82.3968835 C64.4015898,82.7025302 64.2416214,83.1244481 64.2675358,83.5559189 C64.2516967,83.9846235 64.4149126,84.4005728 64.7180042,84.7039199 C65.0210959,85.007267 65.436695,85.1706205 65.8650386,85.154768 M65.6893133,80.7739216 C66.3238959,80.747739 66.9469945,80.9489994 67.4465663,81.341513 L67.4465663,80.6380194 C67.4465663,79.5428078 66.9433529,78.9272509 65.9449137,78.9272509 C65.5723674,78.8864125 65.2008199,79.0123427 64.9297248,79.2713333 C64.6586298,79.5303239 64.5156697,79.8959285 64.5391113,80.2702841 L63.1013588,80.2702841 C63.1013588,78.7833545 64.0358979,77.7121256 65.9289387,77.7121256 C68.0616048,77.7121256 68.9002938,78.9432394 68.9002938,80.8058986 L68.9002938,86.2579739 L67.6063166,86.2579739 L67.6063166,85.5944515 C67.1218794,86.1386139 66.416488,86.4327747 65.6893133,86.393876 C64.6400841,86.4733138 63.6347529,85.9580718 63.0858743,85.0595882 C62.5369956,84.1611046 62.5369956,83.0306758 63.0858743,82.1321921 C63.6347529,81.2337085 64.6400841,80.7184665 65.6893133,80.7979043" id="Shape"></path>
          <rect id="Rectangle" x="70.7454095" y="74.7062894" width="1.50165259" height="11.5516845"></rect>
          <path d="M104.213092,38.8281164 C112.655894,38.8281164 117.871741,44.6239443 117.871741,52.1065579 C117.871741,59.5891715 112.464194,65.5209015 104.261017,65.5209015 C96.0578406,65.5209015 90.6822438,59.7250737 90.6822438,52.2344658 C90.6822438,44.743858 96.2735035,38.8121279 104.197117,38.8121279 M104.292967,58.5179426 C107.959236,58.5179426 110.13184,55.7679222 110.13184,52.1865004 C110.13184,48.6050785 107.735586,45.7911041 104.245042,45.7911041 C100.578773,45.7911041 98.4061697,48.5411245 98.4061697,52.1865004 C98.4061697,55.8318762 100.802424,58.5179426 104.292967,58.5179426" id="Shape"></path>
          <path d="M139.39809,39.2598057 L146.682703,39.2598057 L146.682703,60.8042968 C146.682703,70.4613451 140.93968,73.9868073 132.640654,73.9868073 C129.429732,74.009891 126.25752,73.2845939 123.375138,71.8683323 L125.691517,65.8246828 C127.895465,66.802899 130.277663,67.3145779 132.688579,67.327601 C136.211072,67.327601 138.910852,66.0645102 138.958777,63.5543172 C137.226971,64.9401012 135.046432,65.6400551 132.832354,65.5209015 C124.772953,65.5209015 119.413331,60.1087975 119.413331,52.2344658 C119.413331,44.8477832 124.725028,38.8121279 132.544804,38.8121279 C135.436284,38.8121279 137.800588,39.53161 139.39809,40.8826375 L139.39809,39.2598057 Z M133.16783,58.6298621 C136.985861,58.6298621 139.20639,55.7839107 139.20639,52.2344658 C139.20639,48.6850209 136.810136,45.7671214 133.16783,45.7671214 C129.525523,45.7671214 127.137257,48.6130727 127.137257,52.2344658 C127.137257,55.8558589 129.597411,58.6298621 133.16783,58.6298621" id="Shape"></path>
          <path d="M41.1356959,49.0207792 C41.1356959,47.1341373 40.2171318,45.8790408 38.3800037,45.8790408 C36.5428755,45.8790408 35.6322989,47.0861718 35.6322989,48.9728137 L35.6322989,65.1052008 L27.860448,65.1052008 L27.860448,39.2598057 L35.6322989,39.2598057 L35.6322989,40.898626 C37.0340566,39.5740348 38.9146054,38.8783382 40.8401579,38.9720129 C46.9266434,38.9720129 48.9075468,43.1210262 48.9075468,48.101441 L48.9075468,65.1052008 L41.1356959,65.1052008 L41.1356959,49.0207792 Z" id="Path"></path>
          <path d="M64.6349614,38.8281164 C73.085751,38.8281164 78.277635,44.6239443 78.277635,52.1065579 C78.277635,59.5891715 72.8940507,65.5209015 64.6988615,65.5209015 C56.5036724,65.5209015 51.1200881,59.7410622 51.1200881,52.2504543 C51.0349199,48.6469883 52.4349312,45.1671974 54.9913348,42.6282973 C57.5477385,40.0893972 61.0352937,38.7150864 64.6349614,38.8281164 M64.6988615,58.5339311 C68.3731179,58.5339311 70.5457216,55.7839107 70.5457216,52.2024888 C70.5457216,48.6210669 68.1494675,45.8070926 64.6509365,45.8070926 C60.9846676,45.8070926 58.8120639,48.557113 58.8120639,52.2024888 C58.8120639,55.8478647 61.208318,58.5339311 64.6988615,58.5339311" id="Shape"></path>
          <path d="M8.29902681,54.9045438 C8.6424899,57.3667713 10.5674807,58.9016664 13.5627984,58.9016664 C15.7361412,58.9123511 17.7471082,57.7517874 18.82657,55.8638532 L24.7612927,59.3013787 C23.3075652,62.2912264 19.9288469,65.6968609 13.5148733,65.6968609 C5.64717224,65.7207577 0.239625413,60.7243543 0.239625413,52.4902817 C0.239625413,44.7358637 5.40754682,39.0119841 12.9317848,39.0119841 C20.8474109,39.0119841 25.6239442,44.1762666 25.6239442,51.9067018 C25.6239442,52.7061263 25.6239442,53.6014818 25.528094,54.9045438 L8.29902681,54.9045438 Z M8.49072714,49.4444742 L17.5725303,49.4444742 C17.3896259,47.0635774 15.31965,45.2776115 12.9397723,45.4473516 C10.617278,45.3426262 8.64029877,47.1219835 8.49871465,49.4444742" id="Shape"></path>
          <path d="M12.9317848,34.8309938 C14.4627966,34.823837 15.9898406,34.9873952 17.4846676,35.3186427 L26.0632574,9.65711531 L18.0757437,9.65711531 L13.323173,25.6935714 L8.5865773,9.65711531 L-5.67546989e-14,9.65711531 L8.78626515,35.3186427 C10.1489531,34.9901139 11.5461012,34.8263944 12.9477598,34.8309938" id="Path"></path>
          <path d="M35.5604113,35.3186427 L27.8684355,35.3186427 L27.8684355,9.73705776 L35.5604113,9.73705776 L35.5604113,35.3186427 Z M27.2454095,4.36492491 C27.2518999,2.59233095 28.3250578,0.998340993 29.9641286,0.326739481 C31.6031993,-0.34486203 33.4851213,0.0382977977 34.731756,1.29742969 C35.9783906,2.55656157 36.3440257,4.44349018 35.6580493,6.07774062 C34.9720729,7.71199105 33.3696913,8.77145198 31.5986045,8.76182895 C30.4351139,8.76833248 29.3179534,8.30590541 28.4989935,7.47873561 C27.6800336,6.65156581 27.2282174,5.52928743 27.2454095,4.36492491" id="Shape"></path>
          <path d="M75.5858428,86.2579739 L74.0921777,86.2579739 L74.0921777,77.8080566 L75.5858428,77.8080566 L75.5858428,86.2579739 Z M73.8365773,75.9853686 C73.8321445,75.7197321 73.9361781,75.4637817 74.1246319,75.276678 C74.3130856,75.0895744 74.5696478,74.9875122 74.8350165,74.9940822 C75.1072469,74.9786765 75.3737821,75.0762739 75.5718036,75.263872 C75.7698251,75.4514702 75.8818157,75.712474 75.8813821,75.9853686 C75.8813821,76.2517853 75.7751012,76.5071753 75.5861264,76.6948082 C75.3971516,76.8824412 75.1411379,76.9867806 74.8749541,76.9846493 C74.6094984,76.9868007 74.3542907,76.8822106 74.166579,76.6943407 C73.9788673,76.5064709 73.8743653,76.251048 73.8765149,75.9853686" id="Shape"></path>
          <path d="M79.1882115,77.8080566 L80.7857143,77.8080566 L80.7857143,79.1111186 L79.1882115,79.1111186 L79.1882115,83.9076657 C79.1882115,84.6511306 79.3479618,85.0268601 80.1387257,85.0268601 L80.7377892,85.0268601 L80.7377892,86.3219278 C80.609989,86.3219278 80.2425633,86.4018703 79.755325,86.4018703 C78.1977598,86.4018703 77.6865589,85.6024457 77.6865589,84.2833953 L77.6865589,79.1111186 L76.8398825,79.1111186 L76.8398825,77.8320393 L77.6865589,77.8320393 L77.6865589,76.5129888 L79.1882115,76.5129888 L79.1882115,77.8080566 Z" id="Path"></path>
          <path d="M84.667646,85.154768 C85.5499224,85.154768 86.2651487,84.4389389 86.2651487,83.5559189 C86.2651487,82.672899 85.5499224,81.9570699 84.667646,81.9570699 C84.2358363,81.9284794 83.8126948,82.0877255 83.5066918,82.3939864 C83.2006888,82.7002473 83.0415768,83.1237453 83.0701432,83.5559189 C83.0543041,83.9846235 83.21752,84.4005728 83.5206117,84.7039199 C83.8237033,85.007267 84.2393024,85.1706205 84.667646,85.154768 M84.3002203,74.9780937 L85.5063349,77.1125572 L84.0685824,77.1125572 L82.8225303,74.9780937 L84.3002203,74.9780937 Z M84.4919207,80.7739216 C85.1242797,80.7460976 85.7454255,80.9476433 86.2411862,81.341513 L86.2411862,80.6380194 C86.2411862,79.5428078 85.7379728,78.9272509 84.7475211,78.9272509 C84.3743222,78.8834828 84.0010458,79.0084279 83.7292433,79.2680945 C83.4574407,79.5277611 83.3153834,79.8951398 83.3417187,80.2702841 L81.9359163,80.2702841 C81.9359163,78.7833545 82.8624679,77.7121256 84.7634961,77.7121256 C86.8881748,77.7121256 87.7268638,78.9432394 87.7268638,80.8058986 L87.7268638,86.2579739 L86.4009365,86.2579739 L86.4009365,85.5944515 C85.9182861,86.1363653 85.2163532,86.4303086 84.4919207,86.393876 C83.4426915,86.4733138 82.4373603,85.9580718 81.8884817,85.0595882 C81.339603,84.1611046 81.339603,83.0306758 81.8884817,82.1321921 C82.4373603,81.2337085 83.4426915,80.7184665 84.4919207,80.7979043" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
)


export const Logo = memo(() => {
  const { lang, slug } = useParams()
  const color = useMemo(() => !slug ? '#EFDCC7' : '#76273C', [slug])

  return (
    <LogoStyled href={`/${lang}`} className={`logo`} >
      <_Logo color={color} />
      <span className='srt-only'>Go to Homepage</span>
    </LogoStyled>
  )
})


const LogoStyled = styled.a`
  width: ${vw(175, 'mobile')};
  height: ${vw(44, 'mobile')};

  svg {
    width: 100%;
    height: 100%;
  }
  ${mq.greaterThan('tablet')} {
    width: ${vw(261, 'desktop')};
    height: ${vw(66, 'desktop')};
  }
`
