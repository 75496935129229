import { memo } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, getP53 } from '@/styles'
import { SectionRrss } from './SectionRrss'
import { CallUs } from './CallUs'
import { SectionPages } from './SectionPages'
import { NavList } from './NavList'

export const Footer = memo(() => {
  return (
    <FooterStyled>
      <CallUs />
      <NavList />
      <SectionPages />
      <SectionRrss />
    </FooterStyled>
  )
})


const FooterStyled = styled.footer`
  background-color: var(--color-red);
  padding: ${vw(80, 'mobile')} ${vw(20, 'mobile')} ${vw(40, 'mobile')};
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;

  ${mq.greaterThan('tablet')} {
    ${designGrid({})}
    padding: ${vw(100, 'desktop')} 0 ${vw(100, 'desktop')}!important;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    margin-bottom: ${vw(80, 'mobile')};

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 4;
      grid-row: 1 / span 2;
      margin-bottom: 0;
    }

    li {
      margin-bottom: ${vw(20, 'mobile')};

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(15, 'desktop')};
      }
    }

    a {
      font-size: ${vw(35, 'mobile')};
      font-weight: 700;
      line-height: ${vw(29, 'mobile')};
      letter-spacing: 0.73px;

      ${mq.greaterThan('tablet')} {
        ${getP53()}
        margin-bottom: 0;
        padding-left: 0;
      }

      &.active {
        border-bottom: 0;
        color: var(--color-white);
        text-decoration: none;
      }

      > span {
        display: none;
      }
    }
  }
`
